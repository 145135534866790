import React from 'react';
import Image from 'mui-image';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useLeague from '@/hooks/useLeague';
import { topLeagues } from '@/utils/constants';

import Grid from '@mui/material/Grid2';
import {
  List,
  Paper,
  Skeleton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemButton,
} from '@mui/material';
import Box from '@mui/material/Box';

const leagues = Object.entries(topLeagues);

const LeagueItemAvatar = React.memo(({ slug, name, size = 40 }) => {
  const { ctry } = useLeague(slug);

  return (
    <ListItem component="div" key={name} disableGutters disablePadding>
      <ListItemButton disableRipple component={Link} to={`league/${slug.replace('.', '-')}`}>
        <ListItemIcon>
          <Image
            width={size}
            height={size}
            showLoading={<Skeleton variant="circular" width={size} height={size} />}
            alt={name}
            src={`/img/leagues/120/${slug}.png`}
          />
        </ListItemIcon>
        <ListItemText primary={name} secondary={ctry} />
      </ListItemButton>
    </ListItem>
  );
});

LeagueItemAvatar.propTypes = {
  slug: propTypes.string,
  name: propTypes.string,
  size: propTypes.number,
};

function LeaguesSidebar() {
  return (
    <Grid size={{ xs: 12, md: 3 }}>
      <List
        component={Paper}
        subheader={<ListSubheader component="div">Top Leagues</ListSubheader>}
      >
        {leagues.map(([slug, name]) => (
          <LeagueItemAvatar key={name} slug={slug} name={name} />
        ))}
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2, pb: 0 }}>
      <a href='https://s.click.aliexpress.com/e/_onQJUke?bz=160*600' target='_parent'><img width='160' height='600' src='https://ae01.alicdn.com/kf/S9ab299b905c6418e8a3637d25fe88321c.png' /></a>
            </Box>
    </Grid>
  );
}

export default React.memo(LeaguesSidebar);
