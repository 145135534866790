import { useEffect, useRef } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container'; // import {useDetectAdBlock} from "../../hooks/useDetectAdBlock";
import Footer from './Footer';
import Header from './Header'; // project imports

// project imports
// import FootyLight from "../../views/Match/FootyLight";

// const Sidebar = Loadable(lazy(() => import('./Sidebar')));
// const Header = Loadable(lazy(() => import('./Header')));
// const Footer = Loadable(lazy(() => import('./Footer')));
// const Alternative = Loadable(lazy(() => import('./Alternative')));
// ==============================|| MAIN LAYOUT ||============================== //

function MainLayout() {
  return (
    <Box>
      <Header />
      <Divider />
      {/* <AltenativeWebsites/> */}

      <Box as="a" href='https://s.click.aliexpress.com/e/_onQJUke?bz=725*90' target='_parent' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2, pb: 0 }}>
          <img width='725' height='90' src='https://ae01.alicdn.com/kf/S2d7524ba2465483d8a88f9d75c454fdd2.png' />
      </Box>

      <Container maxWidth="lg" sx={{ py: 2 }}>

          

        <Outlet />
      </Container>
      <Divider />
      <Footer />
      <ScrollRestoration />
    </Box>
  );
}

export default MainLayout;
