import store from '@/store';
import * as React from 'react';
import PropTypes from 'prop-types';
import Head from '@/components/Head';
import { useParams } from 'react-router';
import Loadable from '@/components/Loadable';
import { api, useGameByIdQuery } from '@/store/apiSlice';

import Box from '@mui/material/Box';

import AdsTerra_468x60 from '../../ui-component/AdsTerra_468x60';
import AdInList from '../../ui-component/AdInList';

import Lineup from './Lineup';
import MatchInfo from './Info';
import MatchStats from './Stats';
import GameTimeline from './TimeLine';
import LeagueTable from './LeagueTable';
import MatchHeader from './MatchHeader';

const H2H = Loadable(React.lazy(() => import('./H2H')));
const NotFoundHero = Loadable(React.lazy(() => import('../NotFoundHero')));

export async function loader({ params }) {
  store.dispatch(
    api.endpoints.gameById.initiate(`${params.slug.replaceAll('-', '.')}/${params.id}`)
  );
  return null;
}

function TabContent({ isActive, children }) {
  if (!isActive) {
    return null;
  }
  return <>{children}</>;
}

TabContent.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
};

export function Component() {
  const { id, slug, title, tab = 'info' } = useParams();
  const uid = `${slug.replaceAll('-', '.')}/${id}`;
  const { data, isFetching, isSuccess, isError } = useGameByIdQuery(uid);

  const activeTabs = React.useMemo(
    () =>
      [
        'info',
        data?.kvts && 'timeline',
        data?.bsc?.ts[0]?.stsc?.length > 0 && 'stats',
        data?.stg && 'table',
        data?.rst?.[0]?.rst && 'squad',
      ].filter((t) => t),
    [data]
  );

  const teams = React.useMemo(
    () => new Map((data?.h && data?.a ? [data.h, data.a] : []).map((t) => [t.i, t])),
    [data?.a, data?.h]
  );

  if (isError) {
    return (
      <NotFoundHero
        title="Game not found"
        description="Apologies, but the page you were looking for is ay be broken or not found.
    Try reaching for the home page to look for another one."
      />
    );
  }
  /**
   *                <meta name="description" content={`You can watch ${data.h.t.n.toString()} vs ${data.a.t.n.toString()} live stream online for free at Reddit Soccer Streams. Watch HD Soccer streams for free on Soccer Streams.`} />
   */
  return (
    <>
      <link rel="canonical" href={window.location} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content="https://cdn.statically.io/img/a3.espncdn.com/i/leaguelogos/soccer/500-dark/2.png"
      />
      <meta property="og:url" content={window.location} />
      <meta property="og:site_name" content="Soccer Streams" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content="soccerstreams100" />
      <meta name="twitter:label2" content="Est. reading time" />
      <meta name="twitter:data2" content="1 minute" />

      <MatchHeader
        isLoading={isFetching}
        slug={slug}
        tab={activeTabs.indexOf(tab)}
        activeTabs={activeTabs}
        href={`/event/${slug}/${title}/${id}`}
        {...data}
      />

      <TabContent key={`game-${id}-h2h`} isActive={tab === 'info'}>
        <MatchInfo isLoading={isFetching} slug={slug} {...data} />
        <AdInList />
        <TabContent isActive={isSuccess && !isFetching}>
          <TabContent isActive={!!data?.s?.length}>
            <Box
              component="iframe"
              sx={{
                width: '100%',
                height: (data?.s?.length || 0) * 60 + 55,
              }}
              border="0"
              frameBorder="0"
              src={`${process.env.TAB_URL}/event/${slug.replaceAll('-', '.')}/${id}`}
            />
            <AdInList />
          </TabContent>
          <TabContent isActive={!!data?.h2h?.evs?.length}>
            <H2H getTeam={(i) => teams.get(i)} h2h={data?.h2h} />
          </TabContent>
        </TabContent>
      </TabContent>

      <TabContent isActive={isSuccess && !isFetching}>
        <TabContent isActive={data?.kvts && tab === 'timeline'}>
          <GameTimeline isHome={(i) => teams.get(i)?.ha === 'home'} kvts={data?.kvts} />
          <AdsTerra_468x60 />
        </TabContent>
        <TabContent isActive={tab === 'stats' && !!data?.bsc?.ts[0]?.stsc?.length}>
          <MatchStats ts={data?.bsc?.ts} />
          <AdsTerra_468x60 />
        </TabContent>
        <TabContent isActive={tab === 'squad' && data?.rst}>
          <Lineup rst={data?.rst} />
          <AdInList />
        </TabContent>
        <TabContent isActive={tab === 'table' && data?.stg}>
          <LeagueTable activeIds={[data?.h?.i, data?.a?.i]} data={data?.stg} />
          <AdsTerra_468x60 />
        </TabContent>
        <Head
          title={`${data?.h?.t?.n} vs ${data?.a?.t?.n} live stats - Soccer Stats`}
          description={`You can watch ${data?.h?.t?.n} vs ${data?.a?.t?.n} live stats online for free at Reddit Soccer Stats.Watch HD Soccer Stats for free on Soccer Stats.`}
        />

        <meta
          property="og:description"
          content={`You can watch ${data?.h?.t?.n} vs ${data?.a?.t?.n} live stats online for free at Reddit Soccer Stats.Watch HD Soccer Stats for free on Soccer Stats.`}
        />
        <meta
          property="og:title"
          content={`${data?.h?.t?.n} vs ${data?.a?.t?.n} live stats - Soccer Stats`}
        />
        <h1 style={{ opacity: 0, overflow: 'hidden', height: 0 }}>
          {`${data?.h?.t?.n} vs ${data?.a?.t?.n} Reddit Soccer Stats`}
        </h1>
      </TabContent>
    </>
  );
}
