import * as React from 'react';
import { api } from '@/store/apiSlice';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { filterGame } from '@/utils/filters';

import Competition from './Competition';
import NotFoundHero from '../NotFoundHero';
import AdInList from '../../ui-component/AdInList';

const selectGames = [api.endpoints.getGames.select(), (state) => state.main.gameFilter];

// Selector to filter posts based on a specific criteria
const selectGamesWithTops = createSelector(selectGames, ({ data }, filter) => {
  if (!data?.length) {
    return [];
  }

  return data
    .map((league) => ({
      ...league,
      gs: league.gs.filter((game) => filterGame(game, filter)),
    }))
    .filter(({ gs }) => !!gs.length);
});

function GamesList() {
  const filteredDataByCategory = useSelector(selectGamesWithTops);

  if (!filteredDataByCategory.length) {
    return (
      <NotFoundHero
        title="No matches Found"
        description="Apologies, but the page you were looking for is empty. Try reaching for the search button on the nav bar above to look for another one."
      />
    );
  }

  return (
    <>
      {filteredDataByCategory.map(({ slug, lgn, gs }, index) => (
        <>
        <Competition
          key={slug || lgn}
          shouldDelayRender={index > 4}
          league={lgn}
          slug={slug}
          games={gs}
        />
        {
          index % 4 === 3 && <AdInList />
        }
        </>
      ))}
    </>
  );
}

export default React.memo(GamesList);
